import React from 'react';
import styled from 'styled-components';
import { Header } from '@/features/customer-success/header';
import { VSpace } from '@/components/spacing';
import { Filters } from '@/features/customer-success/filters';
import { TableRoot } from '@/features/customer-success/table';

export function Root() {
  return (
    <Container>
      <VSpace value={4} />
      <Header />
      <Filters />
      <VSpace value={4} />
      <TableRoot />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
