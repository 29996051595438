import React from 'react';

export const getColumns = () => [
  {
    header: `Alias`,
    accessorKey: `alias`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Name`,
    accessorKey: `name`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Variant`,
    // enableHiding: true,
    // enableSorting: true,
    accessorKey: `variant`,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Sessions`,
    accessorKey: `sessions`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Transactions`,
    accessorKey: `transactions`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Revenue`,
    accessorKey: `revenue`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },

  {
    header: `Assisted Revenue`,
    accessorKey: `assistedRevenue`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Clicks`,
    accessorKey: `clicks`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Direct Revenue`,
    accessorKey: `directRevenue`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Entered Checkout`,
    accessorKey: `enteredCheckout`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Order Items Count`,
    accessorKey: `orderItemsCount`,
    // enableHiding: true,
    // enableSorting: true,
    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Page Views`,
    accessorKey: `pageViews`,
    // enableHiding: true,
    // enableSorting: true,
    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Product Detail Views`,
    accessorKey: `productDetailViews`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Profit`,
    accessorKey: `profit`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Returns`,
    accessorKey: `returns`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },

  {
    header: `Signups`,
    accessorKey: `signups`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Subscriptions`,
    accessorKey: `subscriptions`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Sum Sessions Duration`,
    accessorKey: `sumSessionsDuration`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },

  {
    header: `Users`,
    accessorKey: `users`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
  {
    header: `Add To Cart`,
    accessorKey: `addToCart`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },

  {
    header: `Use Case`,
    accessorKey: `use_case`,
    // enableHiding: true,
    // enableSorting: true,

    cell: (p) => <p>{p.getValue()}</p>,
  },
];
