import React from 'react';
import styled from 'styled-components';

export function Header() {
  return <HeaderContainer>A/B TEST REVIEW🦄</HeaderContainer>;
}

const HeaderContainer = styled.h1`
  font-size: 4rem;
`;
