import React, { useContext, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import styled from 'styled-components';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/all';
import { getColumns } from '@/features/customer-success/columns';
import { CSContext } from '@/features/customer-success/context';

type ColumnSort = {
  id: string;
  desc: boolean;
};
type SortingState = ColumnSort[];

export const TableRoot = () => {
  const columns = getColumns();
  const ctx = useContext(CSContext);
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: `sessions`,
      desc: true,
    },
  ]);
  const table = useReactTable({
    data: ctx.state?.metrics?.data?.metrics || [],
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns,
    columnResizeMode: `onChange`,
  });
  // @ts-ignore
  window.foo = table;
  return (
    <Root>
      <Table w={table.getTotalSize()}>
        {table.getHeaderGroups().map((x) => (
          <Tr key={x.id}>
            {x.headers.map((h) => (
              <Th w={h.getSize()} key={h.id}>
                {h.column.getCanSort() && (
                  <Sorter>
                    {
                      {
                        false: (
                          <FaSort
                            size={14}
                            onClick={h.column.getToggleSortingHandler()}
                          />
                        ),
                        asc: (
                          <FaSortUp
                            size={14}
                            onClick={h.column.getToggleSortingHandler()}
                          />
                        ),
                        desc: (
                          <FaSortDown
                            size={14}
                            onClick={h.column.getToggleSortingHandler()}
                          />
                        ),
                      }[`${h.column.getIsSorted()}`]
                    }
                  </Sorter>
                )}
                {h.column.columnDef.header}
                <Resizer
                  onMouseDown={h.getResizeHandler()}
                  onTouchStart={h.getResizeHandler()}
                  className={`resizer ${
                    h.column.getIsResizing() ? `.isResizing` : ``
                  }`}
                />
              </Th>
            ))}
          </Tr>
        ))}
        {table.getRowModel().rows.map((r) => (
          <Tr key={r.id}>
            {r.getVisibleCells().map((c) => (
              <Td w={c.column.getSize()} key={c.id}>
                {flexRender(c.column.columnDef.cell, c.getContext())}
              </Td>
            ))}
          </Tr>
        ))}
      </Table>
    </Root>
  );
};

const Sorter = styled.div`
  &:hover {
    opacity: 1;
    color: red;
    cursor: pointer;
  }
`;
const Root = styled.div`
  overflow: scroll;
  width: 100%;
`;
const Table = styled.table`
  width: ${(p) => p.w}px;
  border: 1px solid #424242;
`;
const Td = styled.td`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.w}px;
`;
const Tr = styled.tr`
  display: flex;
  width: fit-content;
`;
const Th = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  position: relative;
  width: ${(p) => p.w}px;
  border: 1px solid black;
  text-transform: uppercase;
  text-align: center;
`;

const Resizer = styled.div`
  position: absolute;
  opacity: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 5px;
  background-color: #27bbff;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  border-radius: 6px;

  &:hover {
    opacity: 1;
  }

  &.isResizing {
    background-color: #2eff31;
    opacity: 1;
  }
`;

export default Resizer;

// function SortIcon(direction: any, fn: () => void) {
//   return (
//     {
//       asc: <FaSortUp size={14} onClick={fn} />,
//       desc: <FaSortDown size={14} onClick={fn} />,
//     }[direction] || <FaSort size={14} onClick={fn} />
//   );
// }
