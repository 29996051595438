export function awaitCondition(
  condition: () => boolean,
  timeout = 500,
  retries = 4,
) {
  return new Promise((resolve, reject) => {
    if (condition()) {
      resolve(0);
      return;
    }
    if (retries === 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(`condition rejected ${condition?.toString()}`);
      return;
    }
    setTimeout(() => {
      awaitCondition(condition, timeout, retries - 1)
        .then(resolve)
        .catch(reject);
    }, timeout);
  });
}
export async function longPoll<T>(
  action: () => Promise<T | null | undefined>,
  interval = 1000,
  maxAttempts = 100,
): Promise<T> {
  let attempts = 0;

  while (attempts < maxAttempts) {
    // eslint-disable-next-line no-await-in-loop
    const result = await action();
    if (result) return result;

    // eslint-disable-next-line no-await-in-loop,no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, interval));
    attempts += 1;
  }

  throw new Error(`longPoll: Max attempts reached without success.`);
}
