import styled from 'styled-components';

interface P {
  vdisabled: boolean;
  vselected: boolean;
}

export const FilterChip = styled.span`
  background: ${(props: P) =>
    props?.vselected ? `rgba(199, 207, 218, 0.45)` : `transparent`};

  font-size: 1.3rem;
  font-weight: ${(props: P) => (props?.vselected ? `bolder` : `normal`)};
  font-family: 'Eesti', serif;

  color: ${(props: P) => (props?.vselected ? `#000000` : `#8F9DAA`)};

  pointer-events: ${({ vdisabled }: P) => (vdisabled ? `none` : `auto`)};
  cursor: ${({ vdisabled }: P) => (vdisabled ? `default` : `pointer`)};
  opacity: ${({ vdisabled }: P) => (vdisabled ? 0.5 : 1)};

  border-radius: 2rem;
  padding: 0.6rem 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .long-text {
    max-width: 5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }

  &&.selected {
    color: #0086ff;
  }
`;
