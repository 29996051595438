import React, { useRef, useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import styled from 'styled-components';
import { Flexbox } from '@/components/flex';
import { TextInput } from '@/components/textInput';

export function SearchInput({
  defaultValue,
  value,
  onChange,
  grow,
  inputStyle,
  className,
  onInputBlur,
}: {
  defaultValue: string;
  value?: string;
  onChange: (ev) => void;
  grow?: boolean;
  inputStyle?: any;
  className?: string;
  onInputBlur?: () => void;
}) {
  const inputRef = useRef(null);
  const [isBig, setIsBig] = useState(false);
  const iStyles = inputStyle || {};
  const onFocus = () => {
    grow && setIsBig(true);
  };

  const onBlur = () => {
    grow && setIsBig(false);
    onInputBlur && onInputBlur();
  };

  const onSearchIconClick = () => {
    if (inputRef?.current?.focus) {
      inputRef.current.focus();
    }
  };

  return (
    <SearchWrapper className={className}>
      <TextInput
        ref={inputRef}
        defaultValue={defaultValue}
        value={value}
        style={{ ...iStyles }}
        onChange={onChange}
        isBig={isBig}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder="Search"
      />
      <RiSearchLine
        onClick={onSearchIconClick}
        className="search-icon"
        size={15}
      />
    </SearchWrapper>
  );
}

const SearchWrapper = styled(Flexbox)`
  && {
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #8f9daa;

    .search-icon {
      position: absolute;
      right: 1.5rem;
      cursor: pointer;
    }
  }
`;
