import React, { useContext } from 'react';
import { AccountContext } from '@/features/account-context';
import { CSContext, newCSContext } from '@/features/customer-success/context';
import { Root } from '@/features/customer-success/root';
import {
  DataPickerContext,
  newDatePickerCtx,
} from '../dashboard/shared/date-picker/date-picker';

export function CSRoot() {
  const ctx = useContext(AccountContext);

  const csCtx = newCSContext();
  const datePickerCtx = newDatePickerCtx();

  if (ctx?.account?.store?.role !== `system`) {
    return null;
  }
  return (
    <CSContext.Provider value={csCtx}>
      <DataPickerContext.Provider value={datePickerCtx}>
        <Root />
      </DataPickerContext.Provider>
    </CSContext.Provider>
  );
}
