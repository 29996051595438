import { CachePolicies, useFetch } from 'use-http';
import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { routes } from '@/webapi/routes';

export interface MetricsData {
  addToCart: number;
  assistedRevenue: number;
  clicks: number;
  directRevenue: number;
  enteredCheckout: number;
  orderItemsCount: number;
  pageViews: number;
  productDetailViews: number;
  profit: number;
  returns: number;
  revenue: number;
  sessions: number;
  signups: number;
  subscriptions: number;
  sumSessionsDuration: number;
  transactions: number;
  users: number;

  name: string;
  alias: string;
  use_case: string;
  variant: string;
}

export interface DataResponse {
  data: {
    metrics: MetricsData[];
  };
  total: number;
}

interface CcSummaryOpts {
  stores: string[];
  sizes: string[];
  startAt?: Date;
  endAt?: Date;
  latestVersion: boolean;
  chipFilter: string;
  searchToken: string;
  page: number;
  size: number;
}

export function useApi() {
  const { get, post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      cacheLife: 15 * 60,
      loading: true,
    },
  }));
  const [clients, setClients] = useState<Array<string>>([]);
  useAsyncEffect(async (isActive) => {
    const result = await get(routes.listAllAccounts);
    if (isActive() && result?.accounts?.length > 0) {
      setClients(result.accounts);
    }
  }, []);

  const getMetrics = (query: CcSummaryOpts): Promise<DataResponse> =>
    post(routes.csSummary, query);

  return { loading, clients, getMetrics };
}
