import styled from 'styled-components';

export const TextInput = styled.input`
  && {
    height: 100%;
    width: ${(props: { isBig: boolean }) => (props.isBig ? `20rem` : `10rem`)};
    transition: width 0.3s ease-in-out;
    appearance: none;
    -webkit-appearance: none;
    pointer-events: auto;
    outline: none;
    background: ${(props: { defaultValue: string }) =>
      props.defaultValue?.length > 0 ? `rgba(199, 207, 218, 0.45)` : `none`};

    font-size: 1.3rem;
    font-weight: ${(props: { defaultValue: string }) =>
      props.defaultValue?.length > 0 ? `bold` : `normal`};
    font-family: 'Eesti', serif;

    color: ${(props: { defaultValue: string }) =>
      props.defaultValue?.length > 0 ? `black` : `8f9daa`};
    border: none;

    box-shadow: none;

    border-radius: 2rem;

    padding: 0.5rem 3.2rem 0.5rem 1.5rem;

    &&::placeholder {
      color: #8f9daa;
      opacity: 1;
    }

    &&:-ms-input-placeholder {
      color: #8f9daa;
      opacity: 1;
    }

    &&::-ms-input-placeholder {
      color: #8f9daa;
      opacity: 1;
    }

    &&::-webkit-outer-spin-button,
    &&::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &&[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;
