import React, { useContext } from 'react';
import styled from 'styled-components';
import { VscFilter } from 'react-icons/vsc';
import { FilterChip } from '@/components/filter-chip';
import { RangePopover } from '@/features/dashboard/shared/date-picker/range_popover';
import { CheckboxPopover } from '@/components/checkbox-popover';
import { CSContext } from '@/features/customer-success/context';
import { SearchInput } from '@/components/searchInput';
import { BigButton } from '@/components/big-button';
import { HSpace } from '@/components/spacing';
import { Spinner } from '@/Spinner';

export function Filters() {
  const ctx = useContext(CSContext);
  return (
    <Container>
      <Container style={{ padding: `4px 0 0 0` }}>
        <FilterChip
          onClick={() => ctx.toggleChipFilter(`all`)}
          vselected={ctx.state.chipFilter.includes(`all`)}
        >
          All
        </FilterChip>
        <FilterChip
          onClick={() => ctx.toggleChipFilter(`trial`)}
          vselected={ctx.state.chipFilter.includes(`trial`)}
        >
          Trial
        </FilterChip>
        <SizeFilter />
        <ClientFilter />
      </Container>
      <SearchInput
        className="only-desktop"
        defaultValue={ctx.state.searchTerm}
        grow
        onChange={ctx.changeSearch}
      />
      <HSpace />
      <RangePopover />
      <HSpace />
      <FilterChip
        onClick={() => ctx.toggleLatestVersion()}
        vselected={ctx.state.latestVersion}
      >
        LatestVersion
      </FilterChip>
      <HSpace />
      <BigButton onClick={ctx.onSubmit}>
        {!ctx.loading ? (
          `Submit`
        ) : (
          <Spinner size={20} color="pink" mt="0" relative />
        )}
      </BigButton>
      <HSpace />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
function ClientFilter() {
  const ctx = useContext(CSContext);
  return (
    <CheckboxPopover
      key={`clientFilter${Math.random()}`}
      options={ctx.state.aliasesFilter}
      emptyState=""
      onChange={ctx.setAliasFilter}
    >
      <FilterChip
        className={`label-select ${
          ctx.selectedAlias?.length > 0 ? `selected` : ``
        }`}
      >
        <span className="long-text">Clients</span>
        <VscFilter size={14} />
      </FilterChip>
    </CheckboxPopover>
  );
}

function SizeFilter() {
  const ctx = useContext(CSContext);
  return (
    <CheckboxPopover
      key={`sizeFilter${Math.random()}`}
      options={ctx.state.sizeFilter}
      emptyState=""
      onChange={ctx.setSizeFilter}
    >
      <FilterChip
        className={`label-select ${
          ctx.selectedSizeFilter?.length > 0 ? `selected` : ``
        }`}
      >
        <span className="long-text">Size</span>
        <VscFilter size={14} />
      </FilterChip>
    </CheckboxPopover>
  );
}
