/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback';
import { DateParam, useQueryParam } from 'use-query-params';
import { mutateFn, useComplexState } from '@/utils/use-complex-state';
import { CheckboxOption } from '@/components/checkbox-popover';
import { DataResponse, useApi } from '@/features/customer-success/api';
import { DateRangeParam } from '@/features/dashboard/shared/date-picker/date-picker';

interface State {
  aliasesFilter: Array<CheckboxOption>;
  sizeFilter: Array<CheckboxOption>;
  searchTerm: string;
  metrics: DataResponse;
  chipFilter: Array<ChipFilter>;
  latestVersion: boolean;
}

type ChipFilter = 'trial' | 'all';

interface CustomerSuccessContext {
  state: State;
  setSizeFilter: (sizeFilter: CheckboxOption[]) => void;
  selectedSizeFilter: CheckboxOption[];
  changeSearch: DebouncedState<(ev: any) => void>;

  setAliasFilter: (sizeFilter: CheckboxOption[]) => void;
  selectedAlias: CheckboxOption[];
  toggleChipFilter: (s: ChipFilter) => void;
  loading: boolean;
  toggleLatestVersion: () => void;
  onSubmit: () => void;
}

export const CSContext = React.createContext({} as CustomerSuccessContext);

const getSizeFilterAction =
  (dispatch: (fn: mutateFn<State>) => void) =>
  (sizeFilter: Array<CheckboxOption>) =>
    dispatch((state) => {
      if (isAllSelected(sizeFilter)) {
        state.sizeFilter.forEach((size) => {
          size.selected = size.id === `ALL`;
        });
      } else {
        state.sizeFilter = sizeFilter;
      }
    });

export function newCSContext() {
  const [state, dispatch] = useComplexState({
    aliasesFilter: [],
    latestVersion: true,
    sizeFilter: [
      { caption: `ALL`, id: `ALL`, selected: false },
      { caption: `ENT`, id: `ENT`, selected: false },
      { caption: `MM`, id: `MM`, selected: false },
      { caption: `SMB+`, id: `SMB+`, selected: false },
      { caption: `SMB`, id: `SMB`, selected: false },
      { caption: `VSB`, id: `VSB`, selected: false },
    ],
    chipFilter: [`all`],
    trialFilter: false,
    searchTerm: ``,
    metrics: undefined,
  } as State);

  const [dateRange] = useQueryParam(`rn`, DateRangeParam);
  const [startDate] = useQueryParam(`s`, DateParam);
  const [endDate] = useQueryParam(`e`, DateParam);

  useResetLatestVersionOnDatePick(dispatch, endDate, dateRange, startDate);

  const selectedSizeFilter = useMemo(
    () => state.sizeFilter.filter((l) => l.selected),
    [state.sizeFilter],
  );
  useSetClients(dispatch);

  const setSizeFilter = getSizeFilterAction(dispatch);

  const selectedAlias = useMemo(
    () => state.aliasesFilter.filter((l) => l.selected),
    [state.aliasesFilter],
  );

  const setAliasFilter = getAliasFilterAction(dispatch);
  const debouncedSearch = getDebouncedSearchAction(dispatch);
  const toggleChipFilter = getToggleChipFilterAction(dispatch);

  const toggleLatestVersion = () => {
    dispatch((s) => {
      s.latestVersion = !s.latestVersion;
    });
  };

  const { getMetrics, loading } = useApi();
  const onSubmit = async () => {
    if (loading) {
      return;
    }
    const stores = state.aliasesFilter
      .filter((l) => l.selected)
      .map((l) => l.id);
    const sizes = state.sizeFilter.filter((l) => l.selected).map((l) => l.id);
    const metrics = await getMetrics({
      chipFilter: state.chipFilter[0],
      latestVersion: state.latestVersion,
      size: 30,
      sizes,
      stores,
      page: 0,
      // startAt
      // endAt
      searchToken: state.searchTerm,
    });
    dispatch((s) => {
      s.metrics = metrics;
    });
  };

  return {
    onSubmit,
    loading,
    toggleLatestVersion,
    state,
    setSizeFilter,
    selectedSizeFilter,
    changeSearch: debouncedSearch,
    selectedAlias,
    setAliasFilter,
    toggleChipFilter,
  };
}

const useSetClients = (dispatch: (fn: mutateFn<State>) => void) => {
  const { clients, loading } = useApi();
  useEffect(() => {
    if (clients.length > 0) {
      dispatch((draft) => {
        const aliasesFilter = clients.map((alias) => ({
          caption: alias,
          selected: false,
          id: alias,
        }));
        aliasesFilter.push({ caption: `ALL`, id: `ALL`, selected: false });
        draft.aliasesFilter = aliasesFilter.reverse();
      });
    }
  }, [clients.length]);
  return loading;
};
const isAllSelected = (checkboxOptions: Array<CheckboxOption>) =>
  !!checkboxOptions.find((l) => l.selected && l.id === `ALL`);

const useResetLatestVersionOnDatePick = (
  dispatch: (fn: mutateFn<State>) => void,
  endDate: Date,
  dateRange: any,
  startDate: Date,
) => {
  useEffect(() => {
    const elapsedTime = performance.now();
    if (elapsedTime > 3000) {
      dispatch((s) => {
        s.latestVersion = false;
      });
    }
  }, [endDate, dateRange, startDate]);
};
const getToggleChipFilterAction =
  (dispatch: (fn: mutateFn<State>) => void) => (s: ChipFilter) => {
    dispatch((state) => {
      const set = new Set(state.chipFilter);
      if ([`all`].includes(s) && !set.has(s)) {
        state.chipFilter = [s];
        return;
      }
      if (set.has(s)) {
        set.delete(s);
      } else {
        set.add(s);
      }
      if (s !== `all`) {
        set.delete(`all`);
      }
      state.chipFilter = Array.from(set);
    });
  };

const getAliasFilterAction =
  (dispatch: (fn: mutateFn<State>) => void) =>
  (aliasesFilter: Array<CheckboxOption>) =>
    dispatch((state) => {
      if (isAllSelected(aliasesFilter)) {
        state.aliasesFilter.forEach((size) => {
          size.selected = size.id === `ALL`;
        });
      } else {
        state.aliasesFilter = [...aliasesFilter].sort((a) =>
          a.id === `ALL` ? -1 : a.selected ? -1 : 1,
        );
      }
    });

const getDebouncedSearchAction = (dispatch: (fn: mutateFn<State>) => void) =>
  useDebouncedCallback((ev) => {
    dispatch((state) => {
      state.searchTerm = ev.target.value as string;
    });
  }, 300);
